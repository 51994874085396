.logo-div {
  height: 100vh;
  background-color: #668273;
}

p {

}

.logo {
  display: flex;
  text-align: center;
  justify-content: center;
  padding-top: 50px;
  margin: 0 auto;
}

.baner {
  width: 100%;
  max-height: 70%;
  height: auto;
  object-fit: fill;
  position: fixed;
  bottom: 0;
}